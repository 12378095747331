import React, { useState } from 'react';
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import ReactMarkdown from 'react-markdown';

import Layout from '../../components/layout';
import HeadHelmet from '../../components/head';
import ContactDiv from '../../components/contactUs';
import ProcessFlowMobile from "../../components/processFlowMobile"
import ProcessFlowDesktop from "../../components/processFlowDesktop"
import useWindowSize from "../../hooks/useWindowSize"
import { StyledBackground } from '../../components/ui-elements/backgroundImage';
import { HelperDiv } from '../../components/ui-elements/Typography';
import { GetQuoteDiv } from '../../components/pages/solutions/getQuoteDiv';

const CustomFabrication = ({ data, location }) => {
  const { strapiSsFabrication } = data;
  const {
    ssProcess,
    MetaData,
    Hero_image,
    title,
    sectionUnderHero,
    Section_1_content,
    Section_2_title,
    Section_2_content,
    Section_2_image,
    Section_3_title,
    Section_3_content
  } = strapiSsFabrication || {}

  const [active, setActive] = useState(8);
  const { width } = useWindowSize();

  const isMobile = width <= 1023;

  const renderTabs = (data) => {
    const tabArray = []
    data.forEach((node) => {
      tabArray.push(
        <span className={"Services__tabs"} key={node.id}>
          {node.title}
        </span>
      )
    })
    return tabArray
  };

  const renderTabContent = (active) => {
    const activeTabContent = ssProcess.find(
      (node) => node.id === active
    );
    if (!isMobile) {
      return (
        <div>
          <Img
            className={"Services__container--servicesProcessIconsImageHelper"}
            fluid={activeTabContent.image.childImageSharp.fluid}
          />
          <p className={"SharedEl__textContent--center"}>
            {activeTabContent.content}
          </p>
        </div>
      )
    } else {
      return ""
    }
  };

  return (
    <>
      <HeadHelmet data={ MetaData } />
      <Layout>
        <StyledBackground
          img={ Hero_image?.childImageSharp?.fluid }
          title={ title }
          location={ location?.pathname }
          titleWidth="100%"
        />
        <GetQuoteDiv>
          <ReactMarkdown>{ sectionUnderHero }</ReactMarkdown>
        </GetQuoteDiv>
        <div className={"SharedEl__textContent--center"}>
          <HelperDiv>
            <ReactMarkdown>{ Section_1_content }</ReactMarkdown>
          </HelperDiv>
          <Link className={"Services__blueButton"} to="/our-company/contact">
            Contact Us
          </Link>
        </div>
        <div className={"SharedEl__container--greyBG"}>
          <div className={"Wood__desktopGrid"}>
            <div className={"SharedEl__container"}>
              <div
                className={
                  "SharedEl__textContent--center SharedEl__textContent--leftNormalMargin SharedEl__listStyle"
                }
              >
                <div>
                  <div className={"SharedEl__headingsWidth"}>
                    <h3 className={"SharedEl__underline"}>
                      { Section_2_title }
                    </h3>
                  </div>
                  <div className={"SharedEl__ulHelper"}>
                    <ReactMarkdown className={"Wood__listHelper"}>
                      { Section_2_content }
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Img
                className={"Services__container--servicesSecondImageHelper"}
                fluid={ Section_2_image?.childImageSharp?.fluid }
              />
            </div>
          </div>
        </div>
        <div className={"Services__centerDiv"}>
          {isMobile ? (
            <ProcessFlowMobile
              data={ ssProcess }
            />
          ) : (
              <ProcessFlowDesktop
                active={active}
                setActive={setActive}
                onChange={(active) => setActive(active)}
                data={ ssProcess }
              >
                {renderTabs(ssProcess)}
              </ProcessFlowDesktop>
            )}
        </div>

        {renderTabContent(active)}

        <ContactDiv />
      </Layout>
    </>
  )
};

export default CustomFabrication;

export const pageQuery = graphql`
  query {
    strapiSsFabrication {
      id
      MetaData {
        id
        title
        metaKeywords
        metaDescription
      }
      title
      sectionUnderHero
      Hero_image {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90){
            ...GatsbyImageSharpFluid
          }
        }
      }
        Section_1_content
        Section_2_title
        Section_2_content
        Section_2_image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ssProcess {
          id
          title
          content
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Section_3_title
        Section_3_content
      }
  }
`;

